const menuLinksDesktop = [
    { id: 0, title: 'О мероприятии', href: '#about' },
    { id: 1, title: 'Что вас ждет', href: '#program' },
    { id: 2, title: 'Место проведения', href: '#location' },
    { id: 3, title: 'Забронировать место', href: '#buy' },
]

const menuLinksMobile = [
    { id: 0, title: 'О мероприятии', href: '#about' },
    { id: 1, title: 'Что вас ждет', href: '#program' },
    { id: 2, title: 'Место проведения', href: '#location' },
    // { id: 3, title: 'Другие мероприятия', href: '#events' },
]


export {
    menuLinksDesktop,
    menuLinksMobile,
}