import './style.scss'
import { locationSlidePreviews, locationSlides } from 'src/data'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { GalleryModal, YandexMap } from 'src/components'
import { useEffect, useRef, useState } from 'react'
import { disableWindowScroll, enableWindowScroll } from 'src/utils'
import { useIsInViewport } from 'src/hooks'
import { blueGradientImage } from 'src/data/location'

const PREVIEW_SLIDES = [...locationSlidePreviews, ...locationSlidePreviews]
const SLIDES = [...locationSlides, ...locationSlides]

export const Location = () => {
    const [locationImageOpened, setLocationImageOpened] = useState(null)
    const isLocationImageOpened = locationImageOpened !== null

    // отключаем слайдер - если компонент не находится в viewport
    const swiperElement = useRef(null)
    const isIntersected = useIsInViewport(swiperElement)

    useEffect(() => {
        isIntersected
            ? swiperElement.current.swiper.autoplay.start()
            : swiperElement.current.swiper.autoplay.stop()
    }, [isIntersected])


    const openImageViewer = (index) => () => {
        disableWindowScroll()
        swiperElement.current.swiper.autoplay.stop()
        setLocationImageOpened(index)
    }

    const closeImageViewer = () => {
        enableWindowScroll()
        swiperElement.current.swiper.autoplay.start()
        setLocationImageOpened(null)
    }

    const handleOverlayClick = (event) => {
        event.stopPropagation()
        closeImageViewer()
    }
    

    return (
        <section className='location section container'>
            <img className='location__blue-gradient' src={blueGradientImage} alt='' />

            <p className='section__sub-title'>Место проведения</p>
            <h2 id='location' className='section__title title title--md scroll-mt'>Вечеринка в самом <br />центре Москвы</h2>

            <div className='section__body flex-v--c-c'>
                <div className='address'>
                    <h3 className='address__title'>Адрес:</h3>
                    <p className='address__value'>г. Москва, Партийный переулок, 1к6. Концертная площадка 1930.
                        <br />10 минут от м. Павелецкая или м. Добрынинская</p>
                </div>

                {/* <a className='location__btn btn' href='#buy'>Купить билет</a> */}
                <p className='location__btn btn'>Завершено</p>

                <div className='location__map'>
                    <YandexMap coordinates={[55.721136, 37.634703]} hintContent='Вечеринка здесь!' />
                    <div className='map-filter'></div>
                </div>

                {isLocationImageOpened && 
                    <div className='overlay' onClick={handleOverlayClick}>
                        <div className='overlay__v-scroll'>
                            <GalleryModal title='Галерея' index={locationImageOpened} onClose={closeImageViewer} slides={SLIDES} />
                        </div>
                    </div>}
                
                <Swiper
                    id='location-swiper'
                    ref={swiperElement}
                    slidesPerView='auto'
                    spaceBetween={10}
                    // centeredSlides={width <= 768}
                    direction='horizontal'
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className='location__swiper custom-location-swiper'
                >
                    {PREVIEW_SLIDES.map((src, index) => (
                        <SwiperSlide key={index} >
                            <img
                                src={src}
                                onClick={openImageViewer(index)}
                                key={index}
                                alt=''
                            /></SwiperSlide>))}
                </Swiper>
            </div>
        </section>
    )
}