import 'src/styles/index.scss'
import 'src/App.scss'
import { useEffect, useRef, useState } from 'react'
import { Main } from 'src/pages'
import { Footer, Header } from 'src/components'

export const App = () => {
	const intersectionEl = useRef(null)
    const [isIntersected, setIsIntersected] = useState(false)

    useEffect(() => {
        const scrollObserver = new IntersectionObserver((entries) => {
            const newValue = !entries[0].isIntersecting
            if (newValue !== isIntersected) {
                setIsIntersected(newValue)
            }
        })
        scrollObserver.observe(intersectionEl.current)
    }, [])

	return (
		<div className='app' >
			<div ref={intersectionEl}></div>

			<Header isIntersected={isIntersected} />
			<Main />
			<Footer />
		</div>
	)
}