import './style.scss'

export const BurgerBtn = ({ active = false, handleClick, className = '' }) => {
    const burgerBtnClassNames = 'burger-btn'
        + (active ? ' burger-btn--active' : '')
        + (' ' + className)

    return (
        <button onClick={handleClick} className={burgerBtnClassNames}>
            <div className='burger-btn__line burger-btn__lower-line'></div>
            <div className='burger-btn__line burger-btn__upper-line'></div>
        </button>
    )
}
