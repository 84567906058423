import locationSlide01 from  './images/location/slide_01.jpeg'
import locationSlide02 from  './images/location/slide_02.jpeg'
import locationSlide03 from  './images/location/slide_03.jpeg'
import locationSlide04 from  './images/location/slide_04.jpeg'

import locationSlidePreview01 from  './images/location/preview_slide_01.jpeg'
import locationSlidePreview02 from  './images/location/preview_slide_02.jpeg'
import locationSlidePreview03 from  './images/location/preview_slide_03.jpeg'
import locationSlidePreview04 from  './images/location/preview_slide_04.jpeg'

import blueGradientImage from './images/blue-gradient.png'

const locationSlides = [
    locationSlide01,
    locationSlide02,
    locationSlide03,
    locationSlide04,
]

const locationSlidePreviews = [
    locationSlidePreview01,
    locationSlidePreview02,
    locationSlidePreview03,
    locationSlidePreview04,
]


export {
    locationSlides,
    locationSlidePreviews,
    blueGradientImage,
}