export const disableWindowScroll = () => {
    const body = document.querySelector('.body')
    document.documentElement.style.overflow = 'hidden'
    body.classList.add('no-touch-scroll')
}

export const enableWindowScroll = () => {
    const body = document.querySelector('.body')
    document.documentElement.style.overflow = 'auto'
    body.classList.remove('no-touch-scroll')
}