import './style.scss'
import { 
    About,
    Events,
    Hero,
    Location,
    Program
} from './components'

export const Main = () => {
    return (
        <div>
            <Hero />
            <About />
            <Program />
            <Location />
            {/* <Events /> */}
        </div>
    )
}