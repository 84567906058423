import { useState } from 'react'
import './style.scss'
import { useImageOnLoad } from 'src/hooks'

export const GalleryModal = ({ title, index, onClose, slides = [] }) => {
    const [slide, setSlide] = useState(index)
    const [touchPosition, setTouchPosition] = useState(null)

    const isLoading = useImageOnLoad(slides[slide])
    // console.log(slides[index], isLoading)

    const stopPropagation = (event) => event.stopPropagation()

    const nextSlide = () => slide + 1 > slides.length - 1 ? setSlide(0) : setSlide(slide + 1)
    const prevSlide = () => slide - 1 < 0 ? setSlide(slides.length - 1) : setSlide(slide - 1)

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }

    const handleTouchMove = (e) => {
        if (touchPosition === null) {
            return
        }

        const currentPosition = e.touches[0].clientX
        const direction = touchPosition - currentPosition

        const lastSlideIndex = slides.length - 1
        if (direction > 8) {
            if (slide + 1 <= lastSlideIndex) {
                setSlide(prev => prev + 1)
            } else {
                setSlide(0)
            }
        }
        
        if (direction < -8) {
            if (slide - 1 >= 0) {
                setSlide(prev => prev - 1)
            } else {
                setSlide(lastSlideIndex)
            }
        }

        setTouchPosition(null)
    }

    // autoFocus - для предотвращение скролла родительского элемента

    return (
        <div autoFocus onClick={stopPropagation} className='gallery-modal'>
            <div onClick={onClose} className='gallery-modal__close-btn'>
                <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
                    <path d='M11 1L1 11M1 1L11 11' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
            </div>

            <h2 className='gallery-modal__title'>{title}</h2>

            <div className='gallery-modal__image'>
                {isLoading
                    ? <div className='gallery-modal__loader'></div>
                    : <img key={slide} src={slides[slide]} alt='gallery'/>}

                <a className='gallery-modal__zoom-btn' href={slides[slide]} target='_blank' rel='noreferrer'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
                        <path d='M17.523 15.2212L14.9257 12.6238C15.779 11.3367 16.279 9.79589 16.279 8.1395C16.279 3.65138 12.6276 0 8.1395 0C3.65138 0 0 3.65138 0 8.1395C0 12.6276 3.65138 16.279 8.1395 16.279C9.79589 16.279 11.3367 15.779 12.6238 14.9257L15.2212 17.523C15.8563 18.159 16.8879 18.159 17.523 17.523C18.159 16.8876 18.159 15.8566 17.523 15.2212ZM2.44185 8.1395C2.44185 4.99765 4.99765 2.44185 8.1395 2.44185C11.2813 2.44185 13.8372 4.99765 13.8372 8.1395C13.8372 11.2813 11.2813 13.8372 8.1395 13.8372C4.99765 13.8372 2.44185 11.2813 2.44185 8.1395Z' fill='white' />
                        <rect x='7.04688' y='4.87598' width='2.17053' height='6.5116' rx='1.08527' fill='white' />
                        <rect x='11.3877' y='7.04663' width='2.17053' height='6.5116' rx='1.08527' transform='rotate(90 11.3877 7.04663)' fill='white' />
                    </svg>
                </a>

                <div
                    className='gallery-modal__controls'
                    onTouchStart={handleTouchStart} 
                    onTouchMove={handleTouchMove}
                >
                    <button onClick={prevSlide}>
                        <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M8 1L2 7L8 13' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                    </button>

                    <button onClick={nextSlide}>
                        <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M0.999999 1L7 7L0.999999 13' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
}