import { BurgerBtn } from "src/components/BurgerBtn"
import { data } from "src/data"

const MobileMenu = ({ navigationLinks, isMobileMenuOpened, onClose }) => {
    const mobileMenuClassNames = 'mobile-menu'
        + (isMobileMenuOpened ? ' mobile-menu--active' : '')

    const navigationLinksElement = navigationLinks.map(link => {
        return (
            <a onClick={onClose} key={link.id} className='link link--600' href={link.href}>{link.title}</a>
        )
    })

    return (
        <div className={mobileMenuClassNames}>
            <div className='mobile-menu__main-links'>
                {navigationLinksElement}
            </div>

            {/* <a href='#buy' onClick={onClose} className='mobile-menu__btn btn'>
                Купить билет
            </a> */}

            <p className='mobile-menu__btn btn'>
                Завершено
            </p>

            <div className='mobile-menu__footer-links'>
                <a
                    id='uis-phone-to-replace'
                    className='link'
                    href={data.phoneHref}
                >
                    {data.phone}
                </a>
                <a
                    className='link'
                    href={data.emailHref}
                >
                    {data.email}
                </a>
                <a
                    className='link'
                    href={data.mainSiteHref}
                    target='_blank'
                    rel='noreferrer'
                >
                    {data.mainSite}
                </a>
            </div>
        </div>
    )
}

export const MobileNavigation = ({ navigationLinks, isMobileMenuOpened, handleMobileMenuOpen, handleMobileMenuClose }) => {
    const handleBurgerBtnClick = () => {
        isMobileMenuOpened
            ? handleMobileMenuClose()
            : handleMobileMenuOpen()
    }
    
    return (
        <>
            <BurgerBtn active={isMobileMenuOpened} handleClick={handleBurgerBtnClick} className='header__burger-btn' />

            <MobileMenu
                navigationLinks={navigationLinks}
                isMobileMenuOpened={isMobileMenuOpened}
                onClose={handleMobileMenuClose}
            />
        </>
    )
}