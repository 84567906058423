import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import { useEffect, useRef, useState } from 'react'
import { useIsInViewport } from 'src/hooks'
import { useMediaPredicate } from 'react-media-hook'

const ProgramEvent = ({ title, icon }) => {
    return (
        <div className='program-events__item'>
            <div className='program-events__item-grid'>
                <img className='program-events__image' src={icon} alt={title} />
                <p className='program-events__description'>{title}</p>
            </div>
        </div>
    )
}

const SWIPER_AUTOPLAY_DELAY_DEFAULT = 1200

export const ProgramEvents = ({ data }) => {
    const isNotMobileWidth = useMediaPredicate('(min-width: 601px)')
    const [swiperAutoplayDelay, setSwiperAutoplayDelay] = useState(SWIPER_AUTOPLAY_DELAY_DEFAULT)

    // отключаем слайдер - если компонент не находится в viewport
    const swiperElement = useRef(null)
    const isIntersected = useIsInViewport(swiperElement)

    useEffect(() => {
        isIntersected
            ? swiperElement.current.swiper.autoplay.start()
            : swiperElement.current.swiper.autoplay.stop()
    }, [isIntersected])

    useEffect(() => {
        isNotMobileWidth
            ? setSwiperAutoplayDelay(3000)
            : setSwiperAutoplayDelay(SWIPER_AUTOPLAY_DELAY_DEFAULT)
    }, [isNotMobileWidth])

    const eventElements = data.map(({ id, title, icon }) => {
        return (
            <SwiperSlide key={id}>
                <ProgramEvent
                    title={title}
                    icon={icon}
                />
            </SwiperSlide>)
    })

    return (
        <Swiper
            id='program-swiper'
            ref={swiperElement}
            slidesPerView='auto'
            spaceBetween={10}
            // centeredSlides={width <= 768}
            direction='horizontal'
            autoplay={{
                delay: swiperAutoplayDelay,
                disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            className='program-events'
        >
            {eventElements}
        </Swiper>
    )
}