import './style.scss'
import { orangeGradientImage, programEvents } from 'src/data'
import { ProgramEvents, ProgramSchedule } from './components'

export const Program = () => {
    return (
        <section className='program section container'>
            <img className='program__orange-gradient' src={orangeGradientImage} alt='' />

            <p className='section__sub-title'>Что вас ждет</p>
            <h2 id='program' className='section__title title title--md scroll-mt'>Программа</h2>

            <div className='program__body section__body'>
                <ProgramEvents data={programEvents} />
                <ProgramSchedule />
            </div>
        </section>
    )
}