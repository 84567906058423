import { useEffect, useState } from 'react'
import yandexMark from './images/marker.svg'
import './style.scss'

export const YandexMap = ({ coordinates, hintContent = '', balloonContent = '' }) => {
    const [isTwoFingerGesture, setIsTwoFingersGesture] = useState(false)
    const [isPinchZoomStarted, setIsPinchZoomStarted] = useState(false)

    useEffect(() => {
        const ymaps = window.ymaps
        if (ymaps) {
            ymaps.ready(() => {
                const map = new ymaps.Map('map', {
                    center: coordinates,
                    zoom: 16,
                    behaviors: ['drag'], // Включение перемещения карты
                    controls: ['zoomControl']
                })

                // Добавьте метку с пользовательской иконкой
                const placemark = new ymaps.Placemark(coordinates, {
                    hintContent,
                    // balloonContent,
                }, {
                    iconLayout: 'default#image', // Тип макета иконки
                    iconImageHref: yandexMark, // Путь к изображению иконки
                    iconImageSize: [51, 65], // Размер изображения
                    iconOffset: [-13, -(65 / 2.3)] // Смещение иконки
                })

                map.geoObjects.add(placemark)
            })
        }
    }, [window.ymaps]) // eslint-disable-line

    const handleTwoFingerStart = event => {
        if (event.touches.length === 2) {
            setIsTwoFingersGesture(true)
        }
    }

    // Обработчик окончания двухпальцевого жеста
    const handleTwoFingerEnd = event => {
        if (event.touches.length !== 2) {
            setIsTwoFingersGesture(false)
            setIsPinchZoomStarted(false)
        }
    }

    // Обработчик движения при двухпальцевом жесте
    const handleTwoFingerMove = event => {
        if (isTwoFingerGesture) {
            if (!isPinchZoomStarted) {
                setIsPinchZoomStarted(true)
                return
            }

            event.preventDefault()
        }
    }

    return (
        <div
            onTouchStart={handleTwoFingerStart}
            onTouchMove={handleTwoFingerMove}
            onTouchEnd={handleTwoFingerEnd}
            id="map" style={{ width: '100%', height: '100%' }}>
        </div>
    )
}