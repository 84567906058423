import { offerPdf } from "src/data/program"

const ScheduleItem = ({ title, time }) => {
    return (
        <div className='schedule-desktop__event'>
            {time &&
                <p className='schedule-desktop__event-time'>{time}</p>}
            {title &&
                <p className='schedule-desktop__event-title'>{title}</p>}
        </div>
    )
}

const ScheduleList = ({ data = [] }) => {
    const scheduleElements = data.map(({ id, title, time }) => {
        return (
            <ScheduleItem
                key={id}
                title={title}
                time={time}
            />
        )
    })

    return (
        <div className='schedule-desktop__event-list'>
            {scheduleElements}
        </div>
    )
}

export const ScheduleDesktop = ({ data, selectedSchedule, isControlEmelentActive, handleControlElementClick }) => {
    const controlElements = data.map(({ id, title }) => {
        const dataActiveAttr = isControlEmelentActive(id)
            ? 'true'
            : ''

        return (
            <button
                key={id}
                className='schedule-desktop__controls-btn'
                onClick={handleControlElementClick(id)}
                data-active={dataActiveAttr}
            >
                {title}
            </button>
        )
    })

    return (
        <div className='schedule-desktop'>
            <div className='schedule-desktop__controls'>{controlElements}</div>
            <ScheduleList key={selectedSchedule?.id} data={selectedSchedule?.events} />

            {/* ПРАВКА 2.4 */}
            {/* <ScheduleItem
                key={999}
                title='Работа партнерской зоны и бара'
            /> */}
            
            <div key={selectedSchedule?.id + 'divider'} className='schedule-desktop__divider'></div>

            <div key={selectedSchedule?.id + 'footer'} className='schedule-desktop__footer'>
                <div className='price'>
                    <p className='price__title'>Стоимость:</p>

                    <div className='price__value'>
                        {selectedSchedule?.newPrice &&
                            <p>{selectedSchedule?.newPrice} ₽</p>}
                        {selectedSchedule?.oldPrice &&
                            <p>{selectedSchedule?.oldPrice} ₽</p>}
                    </div>
                </div>

                {/* <a className='btn' href={selectedSchedule?.link} target='_blank' rel='noreferrer'>Купить билет</a> */}
                <p className='btn'>Завершено</p>
                <a className='schedule-desktop__footer-link link link--underlined' href={offerPdf} rel='noreferrer' target='_blank'>Ознакомиться <br />с офертой</a>
            </div>
        </div>
    )
}