import './style.scss'
import { data } from 'src/data'

const links = {
    'vk': {
        svg: 
            <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
                <circle cx='20' cy='20' r='20' fill='#281D68' />
                <path fillRule='evenodd' clipRule='evenodd' d='M19.5749 24.9571H20.6231C20.6231 24.9571 20.9386 24.922 21.1007 24.7489C21.2507 24.5879 21.2463 24.2889 21.2463 24.2889C21.2463 24.2889 21.2244 22.8825 21.8773 22.6755C22.5202 22.4706 23.345 24.0337 24.2213 24.6339C24.8829 25.0896 25.3856 24.991 25.3856 24.991L27.7264 24.9581C27.7264 24.9581 28.9499 24.8815 28.3693 23.9198C28.3222 23.8409 28.032 23.2078 26.6299 21.9065C25.1622 20.5439 25.3571 20.7641 27.125 18.408C28.2017 16.9731 28.6322 16.0969 28.4975 15.7223C28.3693 15.3652 27.5774 15.4594 27.5774 15.4594L24.942 15.4758C24.942 15.4758 24.7449 15.4484 24.6025 15.535C24.4601 15.6193 24.3692 15.8176 24.3692 15.8176C24.3692 15.8176 23.9529 16.9271 23.3943 17.8724C22.2223 19.8659 21.7513 19.97 21.5607 19.8462C21.1149 19.557 21.2266 18.6873 21.2266 18.0696C21.2266 16.1385 21.5191 15.3334 20.6571 15.1253C20.369 15.0563 20.1587 15.0103 19.4259 15.0026C18.4839 14.9917 17.6898 15.0059 17.2396 15.2261C16.9395 15.3728 16.7084 15.7004 16.8486 15.719C17.0238 15.7409 17.4181 15.8252 17.6263 16.11C17.8979 16.4769 17.8891 17.3017 17.8891 17.3017C17.8891 17.3017 18.0425 19.5746 17.5277 19.8572C17.1706 20.051 16.6843 19.6556 15.6371 17.8483C15.1004 16.9228 14.6973 15.8986 14.6973 15.8986C14.6973 15.8986 14.6185 15.708 14.4783 15.6051C14.3107 15.4813 14.0752 15.4408 14.0752 15.4408L11.5701 15.4594C11.5701 15.4594 11.1955 15.4703 11.0575 15.6346C10.9348 15.7792 11.0466 16.0793 11.0466 16.0793C11.0466 16.0793 13.0072 20.6655 15.2286 22.9778C17.2637 25.0973 19.5749 24.9581 19.5749 24.9581V24.9571Z' fill='white' />
            </svg>,
        href: data.vk,
    },
    'yt': {
        svg: 
            <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
                <circle cx='20' cy='20' r='20' fill='#281D68' />
                <path fillRule='evenodd' clipRule='evenodd' d='M17.9884 22.5861V16.9743C19.9806 17.9117 21.5236 18.8173 23.3485 19.7936C21.8433 20.6283 19.9806 21.5649 17.9884 22.5861ZM29.091 14.1833C28.7474 13.7305 28.1616 13.3781 27.5381 13.2614C25.7053 12.9134 14.271 12.9124 12.4392 13.2614C11.9391 13.3551 11.4938 13.5817 11.1113 13.9338C9.49964 15.4297 10.0047 23.4517 10.3931 24.7512C10.5565 25.3136 10.7677 25.7193 11.0336 25.9855C11.3763 26.3376 11.8455 26.58 12.3844 26.6887C13.8935 27.0008 21.668 27.1753 27.5062 26.7355C28.0441 26.6418 28.5202 26.3916 28.8958 26.0246C30.3859 24.5347 30.2843 16.0621 29.091 14.1833Z' fill='white' />
            </svg>,
        href: data.yt,
    },
    'tg': {
        svg:
            <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
                <circle cx='20' cy='20' r='20' fill='#281D68' />
                <path fillRule='evenodd' clipRule='evenodd' d='M27.1347 12.1048C28.1089 11.7047 29.1533 12.5083 28.9813 13.5256L26.9495 25.5421C26.7535 26.7007 25.449 27.3656 24.3592 26.7882C23.4474 26.305 22.0944 25.5614 20.8751 24.7843C20.2663 24.3962 18.4019 23.1518 18.6311 22.266C18.827 21.5086 21.9612 18.6628 23.7522 16.9704C24.4557 16.3056 24.1353 15.9215 23.3044 16.5336C21.2434 18.052 17.9343 20.3606 16.8401 21.0103C15.8746 21.5835 15.3705 21.6813 14.7692 21.5835C13.6712 21.4051 12.6533 21.1287 11.8222 20.7926C10.6989 20.3384 10.7536 18.8327 11.8214 18.3942L27.1347 12.1048Z' fill='white' />
            </svg>,
        href: data.tg,
    },
    'dzen': {
        svg:
            <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
                <circle cx='20' cy='20' r='20' fill='#281D68' />
                <g transform='translate(10,10) scale(0.9)'>

                    <path fillRule='evenodd' clipRule='evenodd' d='M17.1954 11.4215C18.797 11.2529 20.3985 11.2108 22 11.1265C21.9579 14.2031 20.5671 17.2797 18.2069 19.3027C16.2682 21.0307 13.6973 21.9579 11.1265 22C11.2108 20.0192 11.2108 18.0383 11.5901 16.0996C11.8429 14.751 12.5594 13.4866 13.6552 12.6437C14.6667 11.8851 15.9732 11.5901 17.1954 11.4215Z' fill='white' />
                    <path fillRule='evenodd' clipRule='evenodd' d='M0 11.1265C1.89655 11.2108 3.83525 11.2108 5.7318 11.5479C6.99617 11.8008 8.21839 12.3487 9.10345 13.318C10.1149 14.456 10.4943 15.9732 10.6628 17.4483C10.8314 18.9655 10.8736 20.4828 10.9157 22C7.83908 21.9579 4.76245 20.5671 2.73946 18.2069C1.01149 16.3104 0.0421456 13.7395 0 11.1265Z' fill='white' />
                    <path fillRule='evenodd' clipRule='evenodd' d='M11.1265 0C14.2874 0.0421456 17.364 1.4751 19.387 3.91954C21.0307 5.85824 21.9579 8.38697 22 10.9157C20.1456 10.8314 18.2912 10.8314 16.4368 10.5364C15.1303 10.3257 13.8659 9.77778 12.9809 8.76628C12.0537 7.79693 11.6322 6.49042 11.4636 5.18391C11.2108 3.45594 11.1686 1.72797 11.1265 0Z' fill='white' />
                    <path fillRule='evenodd' clipRule='evenodd' d='M4.72022 1.98084C6.49033 0.716475 8.6819 0.0421456 10.8735 0C10.7892 2.10728 10.8313 4.2567 10.3256 6.32184C9.94627 7.79693 8.97692 9.14559 7.58612 9.86207C6.2796 10.5364 4.80451 10.6628 3.32941 10.7893C2.19148 10.8736 1.09569 10.9157 -0.0422363 10.9157C0.0420549 7.37548 1.85432 3.96169 4.72022 1.98084Z' fill='white' />
                </g>
            </svg>,
        href: data.dzen,
    },
}

export const SocialLink = ({ social = 'vk' }) => {
    const { href, svg } = links[social]
    return (
        <a className='social-link' href={href} target='_blank' rel='noreferrer'>
            {svg}
        </a>
    )
}