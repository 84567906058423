import './style.scss'
import { heroImage } from 'src/data'
export const Hero = () => {
    return (
        <section id='start' className='hero' style={{ backgroundImage: `url('${heroImage}')` }}>
            <div className='hero__overlay'>

                <div className='container flex-v--c-c'>
                    <h1 className='hero__title title'>DDX Fitness <br /> GO</h1>

                    <div className='hero__tags'>
                        <div className='tag tag--sm'>3 февраля</div>
                        <div className='tag tag--sm'>"1930 Moscow"</div>
                    </div>

                    {/* <a href='#buy' className='hero__btn btn'>Иду!</a> */}
                    <p className='hero__btn btn'>Завершено</p>
                </div>

            </div>
        </section>
    )
}