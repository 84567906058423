import { offerPdf } from "src/data/program"

const ScheduleMobileItem = ({ item, isItemOpened, handleClick }) => {
    const scheduleItemClassNames = (id) => isItemOpened(id)
        ? 'schedule-mobile__item schedule-mobile__item--active'
        : 'schedule-mobile__item'

    const scheduleBtnMobileClassNames = (id) => isItemOpened(id)
        ? 'schedule-mobile__item-btn schedule-mobile__item-btn--active'
        : 'schedule-mobile__item-btn'

    return (
        <div key={item.id} className={scheduleItemClassNames(item.id)}>
            <button onClick={handleClick(item.id)} className={scheduleBtnMobileClassNames(item.id)}>
                <p>{item.title}</p>
                <div>
                    <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M2 3L8 9L14 3' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                    </svg>
                </div>
            </button>

            {isItemOpened(item.id) &&
                <div className='schedule-mobile__item-container'>
                    {item.events.map((scheduleItem, index) => {
                        return (
                            <div key={index} className='schedule-mobile__item-container-item'>
                                <p>{scheduleItem.time}</p>
                                <p>{scheduleItem.title}</p>
                            </div>
                        )
                    })}

                    {/* ПРАВКА 2.4 */}
                    {/* <br />
                    <div key={888} className='schedule-mobile__item-container-item'>
                        <p>Работа партнерской зоны и бара</p>
                    </div> */}

                    <div className='schedule-mobile__divider'></div>

                    <div className='price'>
                        <p className='price__title'>Стоимость:</p>
                        <div className='price__value'>
                            {item?.newPrice &&
                                <p>{item?.newPrice} ₽</p>}
                            {item?.oldPrice &&
                                <p>{item?.oldPrice} ₽</p>}
                        </div>
                        {/* <p className='price__ps'>*с 01.02.2024 повышение цены</p> */}
                    </div>

                </div>}
        </div>
    )
}

export const ScheduleMobile = ({ data, selectedSchedule, isControlEmelentActive, handleControlElementClick }) => {
    const isBuyButtonDisabled = !selectedSchedule
    const buyButtonClassNames = 'btn ' + (isBuyButtonDisabled ? 'btn--disabled' : '')

    const scheduleMobileElements = data.map(item => {
        return (
            <ScheduleMobileItem
                key={item.id}
                item={item}
                isItemOpened={isControlEmelentActive}
                handleClick={handleControlElementClick}
            />
        )
    })

    return (
        <div className='schedule-mobile'>
            {scheduleMobileElements}

            {/* <a className={buyButtonClassNames} href={selectedSchedule?.link} target='_blank' rel='noreferrer'>Купить билет</a> */}
            <p className='btn'>Завершено</p>
            <a className='schedule-mobile__offer-link link link--underlined' href={offerPdf} rel='noreferrer' target='_blank'>Ознакомиться с офертой</a>
        </div>
    )
}