import { useEffect, useState } from 'react'
import { programImage, eventSchedules } from 'src/data'
import { useMediaPredicate } from 'react-media-hook'

import { ScheduleDesktop } from './ScheduleDesktop'
import { ScheduleMobile } from './ScheduleMobile'

export const ProgramSchedule = () => {
    const isDesktopWidth = useMediaPredicate('(min-width: 1101px)')
    const [selectedSchedule, setSelectedSchedule] = useState(eventSchedules[0])
    
    const isControlEmelentActive = (id) => id === selectedSchedule?.id
    const handleControlElementClick = (id) => {
        return () => setSelectedSchedule(eventSchedules.find(schedule => schedule.id === id))
    }
    const handleMobileElementClick = (id) => {
        return () => isControlEmelentActive(id)
            ? setSelectedSchedule(null)
            : setSelectedSchedule(eventSchedules.find(schedule => schedule.id === id))
    }

    useEffect(() => {
        const isNoSelectedEventScheduleOnDesktopWidth = isDesktopWidth && !selectedSchedule
        if (isNoSelectedEventScheduleOnDesktopWidth) {
            setSelectedSchedule(eventSchedules[0])
        }
    }, [isDesktopWidth])

    return (
        <div className='schedule'>
            <div className='schedule__image'>
                <img src={programImage} alt='Программа' />
            </div>

            <div id='buy' className='scroll-mt'>
                <ScheduleDesktop
                    data={eventSchedules}
                    selectedSchedule={selectedSchedule}
                    isControlEmelentActive={isControlEmelentActive}
                    handleControlElementClick={handleControlElementClick}
                />

                <ScheduleMobile
                    data={eventSchedules}
                    selectedSchedule={selectedSchedule}
                    isControlEmelentActive={isControlEmelentActive}
                    handleControlElementClick={handleMobileElementClick}
                />
            </div>
        </div>
    )
}