
import programIcon00 from './images/program/program_01.png'
import programIcon01 from './images/program/program_02.png'
import programIcon02 from './images/program/program_03.png'
import programIcon03 from './images/program/program_04.png'
import programIcon04 from './images/program/program_05.png'
import programIcon05 from './images/program/program_06.png'

import orangeGradientImage from './images/orange-gradient.png'
import programImage from './images/program.png'

import offerPdf from './files/oferta.pdf'


const programEvents = [
    { id: 0, title: 'Любимые групповые тренировки', icon: programIcon00 },
    { id: 1, title: 'Зона отдыха', icon: programIcon01 },
    { id: 2, title: 'Розыгрыш призов', icon: programIcon02 },
    { id: 3, title: 'Яркие спецэффекты', icon: programIcon03 },
    { id: 4, title: 'Танцевальная вечеринка', icon: programIcon04 },
    { id: 5, title: 'Живой звук', icon: programIcon05 },

    /* ДУБЛИРУЕМ ДЛЯ КОРРЕКТРОЙ РАБОТЫ СЛАЙДЕРА */
    { id: 6, title: 'Любимые групповые тренировки', icon: programIcon00 },
    { id: 7, title: 'Зона отдыха', icon: programIcon01 },
    { id: 8, title: 'Розыгрыш призов', icon: programIcon02 },
    { id: 9, title: 'Яркие спецэффекты', icon: programIcon03 },
    { id: 10, title: 'Танцевальная вечеринка', icon: programIcon04 },
    { id: 11, title: 'Живой звук', icon: programIcon05 },
]

const eventSchedules = [
    // { id: 0, title: 'Дневной сеанс', newPrice: '2500', oldPrice: '3000', link: 'https://events.nethouse.ru/form/85601/tickets/142724/', events: 
    //     [
    //         { id: 0, time: '10:00', title: 'Открытие' },
    //         { id: 1, time: '10:10', title: 'BODYBALLET' },
    //         { id: 2, time: '11:00', title: 'Lady style' },
    //         { id: 3, time: '12:00', title: 'Развлекательная программа' },
    //         { id: 4, time: '12:30', title: 'Зумба' },
    //         { id: 5, time: '13:30', title: 'BODYCOMBAT' },
    //     ]
    // },
    { id: 1, title: 'DDX Fitness GO', newPrice: '4000', link: 'https://events.nethouse.ru/form/85601/tickets/142793/', events: 
        [
            { id: 0, time: '16:00', title: 'Официальное открытие DDX Fitness GO' },
            { id: 1, time: '16:10', title: 'Струнный BODYBALLET' },
            { id: 2, time: '17:00', title: 'LADYSTYLE под зажигательный саксофон' },
            { id: 3, time: '18:00', title: 'BODYCOMBAT со спецэффектами' },
            { id: 4, time: '19:00', title: 'Развлекательная программа и розыгрыш призов' },
            { id: 5, time: '19:10', title: 'Жаркая африканская ZUMBA' },
            { id: 6, time: '20:00', title: 'Выступление кавер-группа "Немодные"' },
        ]
    },
]

// old //
// const eventSchedules = [
//     { id: 0, title: 'Дневной сеанс', newPrice: '2000', oldPrice: '2500', link: 'https://events.nethouse.ru/form/85601/tickets/142724/', events: 
//         [
//             { id: 0, time: '10:30', title: 'Открытие' },
//             { id: 1, time: '10:40', title: 'BODYBALLET' },
//             { id: 2, time: '11:25', title: 'Lady style' },
//             { id: 3, time: '12:10', title: 'Развлекательная программа' },
//             { id: 4, time: '12:30', title: 'Зумба' },
//             { id: 5, time: '13:15', title: 'BODYCOMBAT' },
//         ]
//     },
//     { id: 1, title: 'Вечерний сеанс', newPrice: '3000', oldPrice: '3500', link: 'https://events.nethouse.ru/form/85601/tickets/142793/', events: 
//         [
//             { id: 0, time: '16:00', title: 'Открытие' },
//             { id: 1, time: '16:10', title: 'BODYBALLET' },
//             { id: 2, time: '16:55', title: 'Lady style' },
//             { id: 3, time: '17:40', title: 'BODYCOMBAT' },
//             { id: 4, time: '18:40', title: 'Развлекательная программа' },
//             { id: 5, time: '18:55', title: 'Зумба' },
//             { id: 6, time: '19:50', title: 'Выступление кавер-группы' },
//         ]
//     },
// ]


export { 
    programEvents,
    eventSchedules,
    orangeGradientImage,
    programImage,
    offerPdf,
}